import request from '@/utils/request'

export function orderList (query) {
  return request({
    url: '/shop/cashier/order/',
    method: 'get',
    params: query
  })
}
export function orderStatisticsList (query) {
  return request({
    url: '/shop/order_statistics/',
    method: 'get',
    params: query
  })
}
export function create_pick_list (parameter) {
  return request({
    url: '/wechat/create_pick_list/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderToExamine (parameter) {
  return request({
    url: '/shop/order/examine/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderDetail (order_no) {
  return request({
    url: '/shop/cashier/order_goods/?order_no=' + order_no,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function parentCategoryName () {
  return request({
    url: '/wechat/parent_category/name/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function order_refund (parameter) {
  return request({
    url: '/wechat/order/refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function detailGoods (id) {
  return request({
    url: '/goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/wechat_mall_goods/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}

// 退款列表
export function refundList (query) {
  return request({
    url: '/shop/cashier_order/apply_refund/',
    method: 'get',
    params: query
  })
}

// 退款
export function doRefund (parameter) {
  return request({
    url: '/shop/refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 退款
export function doBackApplyRefund (parameter) {
  return request({
    url: '/shop/cashier_order/apply_refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 检验完整性
export function putRefund (parameter, refund_id) {
  return request({
    url: '/shop/refund/' + refund_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 退货地址
export function refundAddressList (query) {
  return request({
    url: '/shop/refund_address/',
    method: 'get',
    params: query
  })
}
export function createRefundAddress (parameter) {
  return request({
    url: '/shop/refund_address/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function putRefundAddress (parameter, id) {
  return request({
    url: '/shop/refund_address/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteRefundAddress (id) {
  return request({
    url: '/shop/refund_address/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function refundExamine (parameter) {
  return request({
    url: '/shop/refund_examine/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

// 退货商品列表
export function refundGoodsList (query) {
  return request({
    url: '/shop/cashier_order/refund/goods/',
    method: 'get',
    params: query
  })
}

// 退货商品放至退货区
export function createRefundGoods (parameter) {
  return request({
    url: '/wms/refund_order/goods_to_warehouse/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putRefundGoods (parameter, id) {
  return request({
    url: '/shop/refund_goods/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteRefundGoods (id) {
  return request({
    url: '/shop/refund_goods/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function putReceived (parameter) {
  return request({
    url: '/shop/order/back_end_apply_refund/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderToWarehouse (parameter) {
  return request({
    url: '/shop/order_to_warehouse/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderPayMethodDetail (order_no) {
  return request({
    url: '/shop/cashier/order_pay_methodes/?order_no=' + order_no,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
