<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24" v-if="false">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" allow-clear placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.applet_order_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="handle_to_warehouse_config">订单配置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text/100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleDetail(record)">详情</a>
            <a-divider type="vertical" v-if="record.status !== 8&&record.status !== 7&&record.status !== 5&&record.status >=2"/>
            <a @click="handleRefund(record)" v-if="record.status !== 8&&record.status !== 7&&record.status !== 5&&record.status >=2">退款</a>
            <a-divider type="vertical" v-if="record.pay_method_mix"/>
            <a @click="handlePayMethodDetail(record)" v-if="record.pay_method_mix">支付详情</a>
          </template>
        </span>
      </s-table>
      <a-modal
        v-model="select_status_visible"
        :confirm-loading="confirm_loading"
        title="审核"
        ok-text="确认"
        cancel-text="取消"
        @ok="select_status">
        <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.applet_order_exam_status"
            :key="key"
            :value="key">
            {{ val }}
          </a-select-option>
        </a-select>
        <br />
        <br />
        <!--  <span v-if="select_status_data === '5'">-->
        <!--  退款金额：<a-input-number :min="0" v-model="refund" style="width: 180px"/> 元</span>-->
      </a-modal>
      <a-modal v-model="refund_status_visible" title="退款" ok-text="确认" cancel-text="取消" @ok="select_refund">
        <div>
          <a-input v-model="refund_reason" placeholder="请输入理由"/>
        </div>
      </a-modal>
      <a-drawer
        :title="'订单：' + this.orderInfo.order_no"
        placement="right"
        :closable="false"
        width="900"
        v-if="orderPayMethodVisible"
        :visible.sync="orderPayMethodVisible"
        @close="onOrderPayMethodClose"
        :drawer-style="{ position: 'absolute' }"
        style="position: absolute"
      >
        <a-table
          size="small"
          rowKey="id"
          :loading="orderPayMethodLoading"
          :columns="orderPayMethodColumns"
          :data-source="orderPayMethodes" >
          <div slot="price_render" slot-scope="text" style="min-width: 110px">
            {{ (text/100).toFixed(2) }}
          </div>
        </a-table>
      </a-drawer>
      <order-circulate-config
        ref="configModal"
        v-if="configVisible"
        :visible="configVisible"
        :loading="confirmLoading"
        :title="title"
        :model="mdl"
        @cancel="handleConfigCancel"
        @ok="handleConfigOk"
      />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import OrderCirculateConfig from '@/views/a-applet/shop_manage/OrderCirculateConfig'
import {
  doBackApplyRefund,
  orderToExamine,
  orderList,
  putReceived,
  orderToWarehouse,
  orderPayMethodDetail
} from '@/api/counter_shop_order'
import { orderToWarehouseConfig, putOrderToWarehouseConfig } from '@/api/applet_shopmall_order'

export default {
  name: 'TableList',
  components: {
    STable,
    OrderCirculateConfig
  },
  data () {
    return {
      title: '自动转到仓库订单',
      pageNum: 1,
      configVisible: false,
      select_status_visible: false,
      confirm_loading: false,
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: (record.status !== 2)
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      orderPayMethodVisible: false,
      orderPayMethodLoading: false,
      orderInfo: {},
      orderPayMethodes: [],
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '支付类型',
          ellipsis: true,
          dataIndex: 'order_pay_method'
        },
        {
          title: '付款方式',
          ellipsis: true,
          dataIndex: 'pay_method'
        },
        {
          title: '支付金额',
          dataIndex: 'actual_paid',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.applet_order_status[text] || '无'
        },
        {
          title: '下单时间',
          width: 150,
          align: 'center',
          dataIndex: 'create_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '支付时间',
          dataIndex: 'pay_time',
          width: 150,
          align: 'center',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      orderPayMethodColumns: [
        {
          title: '付款方式',
          ellipsis: true,
          dataIndex: 'pay_method'
        },
        {
          title: '支付金额',
          dataIndex: 'actual_paid',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '下单时间',
          dataIndex: 'create_time'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            return res.data
          })
      }
    }
  },
  created () {
    this.tableOption()
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: (record.status !== 2) || (record.is_sync)
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      console.log(record.id)
      this.$router.push({ path: 'counter_order/detail', query: { order_no: record.order_no, status: record.status } })
    },
    handlePayMethodDetail (record) {
      console.log(record.order_no)
      this.orderPayMethodes = []
      this.orderInfo = record
      this.orderPayMethodVisible = true
      this.orderPayMethodLoading = true
      orderPayMethodDetail(record.order_no).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.orderPayMethodes = res.data.entries
        }
      }).finally(() => {
        this.orderPayMethodLoading = false
      })
    },
    onOrderPayMethodClose () {
      this.orderPayMethodVisible = false
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.distribution = undefined
      } else {
        this.queryParam.distribution = 2
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      const param = {
        reason: this.refund_reason,
        order_no: this.order_id
      }

      doBackApplyRefund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_warehouse () {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const filterRows = this.selectedRows.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== this.selectedRows.length) {
          this.$message.warning('选择已支付未发货订单')
        } else {
          const data = {
            order_ids: this.selectedRowKeys
          }
          orderToWarehouse(data).then(res => {
            if (res.code === 1000) {
              this.$message.warning('操作成功')
              this.selectedRowKeys = []
              this.selectedRows = []
              this.$refs.table.refresh(true)
            }
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 订单退款
    handleRefund (record) {
      this.order_id = record.order_no
      this.refund_status_visible = true
      // // // 2 审核通过，5 审核不通过
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$router.push({ path: 'counter_order/detail_refund', query: { id: record.id, status: record.status } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    handleReceived (value) {
      putReceived({ order_no: value.order_no }).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    handleAgreeRefund (value) {
      putReceived({ order_no: value.order_no }).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleConfigCancel () {
      this.configVisible = false
      this.confirmLoading = false
    },
    handleConfigOk () {
      const form = this.$refs.configModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          putOrderToWarehouseConfig(values, this.mdl.id).then(res => {
            if (res.code === 1000) {
              this.handleConfigCancel()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handle_to_warehouse_config () {
      orderToWarehouseConfig({ type: 2 }).then(res => {
        console.log(res)
        if (res.code === 1000 && res.data.entries.length > 0) {
          this.mdl = res.data.entries[0]
          this.configVisible = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>
